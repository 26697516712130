<template>
  <div class="">

  </div>
</template>

<script>
export default {
props:{
    title:String
},
watch:{
'$i18n.locale':function(){
    console.log("step 14 ", );
    
    document.title=this.title
}
},
mounted() {
    document.title=this.title
},
}
</script>

<style>

</style>