export default [
    {
      path: "/all-transactions",
      name: "allTransactions",
  
      component: () => import("@/views/transactions/allTransactions.vue"),
      meta: {
        auth_roles: ["All", "Transactions"],
        authRequired: true,
        title: "Transactions",
      },
    },
    {
      path: "/cancel-transaction",
      name: "cancelTransaction",
  
      component: () => import("@/views/transactions/cancelTransaction.vue"),
      meta: {
        auth_roles: ["All", "Transactions"],
        authRequired: true,
        title: "cancelTransaction",
      },
    },
    {
      path: "/cancelled-transactions",
      name: "cancelledTransactions",
  
      component: () => import("@/views/transactions/cancelledTransactions.vue"),
      meta: {
        auth_roles: ["All", "Transactions"],
        authRequired: true,
        title: "cancelledTransactions",
      },
    },
  ];
  