import { createStore } from "vuex";

import modules from "./modules";

const store = createStore({
  state: {
    /*  */
    baseUrl:process.env.NODE_ENV =='development'?  'http://192.168.137.1:2024/'  : "https://mobile.ar-bank.sd/api/" ,
    mediaUrl:process.env.NODE_ENV =='development'?  'http://192.168.137.1:2024' : "https://mobile.ar-bank.sd/api",
    serverUrl:process.env.NODE_ENV =='development'?  'http://192.168.137.1:2024' : "https://mobile.ar-bank.sd/api",

    currentUser: {},
    token: null,
    // token: 'WTU9v6cgR9MKZN47EYdBHrh1rWnUm1Ea',
  },
  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value;
    },
    SET_USER(state, value) {
      console.log(state.user);
      state.user = value;
    },
    addUserData(state, value) {
      state.currentUser = value;

      localStorage.setItem("user", JSON.stringify(value));
      console.log("userData :", state.currentUser);
    },
    changeLang() {
  
      
      let bootstrap,appStyle
      bootstrap = document.querySelector('#bootstrap-style')
      appStyle= document.querySelector('#app-style')
      if (localStorage.getItem("current_language") == "ar") {
  
  
        
        bootstrap.setAttribute("href", "/assets/css/bootstrap-rtl.min.css")
        appStyle.setAttribute('href','/assets/css/app-rtl.min.css')
        setTimeout(() => {
          console.log("loading end");
        }, 300);
        
      } else {
  
        bootstrap.setAttribute("href", "/assets/css/bootstrap.min.css")
        appStyle.setAttribute('href','/assets/css/app.min.css')
  
        setTimeout(() => {
          console.log("loading end");
        }, 300);
  
      }
    }
  },
  action: {
  },
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== "production",
});
console.log("step 62 :",store);

export default store;
